import { useEffect, useState, MutableRefObject } from 'react'

/*
 * Detects if a DOM ref is currently visible to the user.
 */
export const useOnScreen = ({
  ref,
  rootMargin = '0px',
  root,
  setOnce = false
}: {
  ref: MutableRefObject<any>
  rootMargin?: string
  root?: MutableRefObject<any>
  setOnce?: boolean
}) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const c = ref.current
    if (!c) return
    const observer = new IntersectionObserver(
      ([el]) => {
        setIntersecting(state => {
          if (setOnce && state) {
            return state
          }
          return el.isIntersecting
        })
      },
      {
        root: root?.current,
        rootMargin
      }
    )
    if (c) {
      observer.observe(c)
    }
    return () => {
      observer.unobserve(c)
    }
  }, [ref, rootMargin, root, setOnce])

  return isIntersecting
}
